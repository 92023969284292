// 焦虑筛查量表 GAD-7
export const survey = {
  title: "",
  logoPosition: "right",
  showCompletedPage: false, //问卷完成后显示完成提示
  navigateToUrl: "", //问卷完成后跳转地址
  checkErrorsMode: "onValueChanged", //答案改变时立即校验
  widthMode: "responsive",
  width: "500px",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "panel",
          name: "emotionalInfluence",
          elements: [
            {
              type: "radiogroup",
              name: "uneasy",
              title: "1、感到不安、担心及烦躁",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "worry",
              title: "2、不能停止或无法控制担心",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "worryTooMuch",
              title: "3、对各种各样的事情担忧过多",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "nervous",
              title: "4、很紧张、很难放松下来",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "restless",
              title: "5、非常焦躁、以至无法静坐",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "testiness",
              title: "6、变得容易烦恼或易被激怒",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "horribleFeel",
              title: "7、感到好像有什么可怕的事会发生",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
          ],
          title: "在过去的两周里,你生活中以下症状出现的频率有多少?",
          isRequired: true,
        },
      ],
    },
  ],
};

export const onlyOneTimesData = [];

// 评分等级
// 0-4为1,5-9为2,10-13为3,14-18为4，19-21为5
// const levelResult = {
//   1: "您目前没有焦虑症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
//   2: "您可能存在轻微焦虑症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持，并提供适当的治疗选择。",
//   3: "您可能存在中度焦虑症状。这种情况可能会对您的生活产生重大影响，建议您尽快咨询专业心理健康专家。",
//   4: "您正在存在中重度焦虑症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。",
//   5: "您可能存在严重的焦虑症状。我明白这对您来说可能是一个巨大的排战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。",
// };

// 总分=所有累加

function allScore(data) {
  // 评价等级
  // 0-4为1,5-9为2,10-13为3,14-18为4，19-21为5
  // let levelResult = {
  //   1: "您目前没有焦虑症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
  //   2: "您可能存在轻微焦虑症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持，并提供适当的治疗选择。",
  //   3: "您可能存在中度焦虑症状。这种情况可能会对您的生活产生重大影响，建议您尽快咨询专业心理健康专家。",
  //   4: "您正在存在中重度焦虑症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。",
  //   5: "您可能存在严重的焦虑症状。我明白这对您来说可能是一个巨大的排战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。"
  // };
  let levelResult = {
    1: {
      level: 1,
      title: "心理状态良好",
      desc: "您目前没有焦虑症状",
      content:
        "您目前没有焦虑症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
    },
    2: {
      level: 2,
      title: "轻度焦虑",
      desc: "您可能存在轻度焦虑",
      content:
        "您可能存在轻微焦虑症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持，并提供适当的治疗选择。",
    },
    3: {
      level: 3,
      title: "中度焦虑",
      desc: "您可能存在中度焦虑",
      content:
        "您可能存在中度焦虑症状。这种情况可能会对您的生活产生重大影响，建议您尽快咨询专业心理健康专家。",
    },
    4: {
      level: 4,
      title: "中重度焦虑",
      desc: "您可能存在中重度焦虑",
      content:
        "您正在存在中重度焦虑症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。",
    },
    5: {
      level: 5,
      title: "重度焦虑",
      desc: "您可能存在重度焦虑",
      content:
        "您可能存在严重的焦虑症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。建议您尽快咨询专业心理健康专家并帮助您应对这些症状。",
    },
  };
  const GAD = eval(Object.values(data).join("+"));
  let result = {};
  if (0 <= GAD && GAD <= 4) {
    result = levelResult[1];
  }
  if (5 <= GAD && GAD <= 9) {
    result = levelResult[2];
  }
  if (10 <= GAD && GAD <= 13) {
    result = levelResult[3];
  }
  if (14 <= GAD && GAD <= 18) {
    result = levelResult[4];
  }
  if (19 <= GAD && GAD <= 21) {
    result = levelResult[5];
  }
  console.log(data, GAD, result);
  return {
    score: { allScore: GAD },
    result: { levelResult: result },
  };
}

export const calculator = (data) => {
  console.log(data);

  let resultInfo = allScore(data);
  return {
    conclusion: resultInfo.result, //结论
    score: resultInfo.score, //分数
    answerInfo: data, //回答答案
    desc: "", //问卷特殊数据
  };
};
