<template>
  <div class="formgion">
    <!-- <div class="title">{{ currQuestionsInfo.name }}:</div> -->
    <el-form
      ref="form"
      label-width="80px"
      label-position="top"
      class="form-box"
    >
      <el-form-item
        :label="question.questionContent"
        v-for="(question, _index) in questionsList"
        :key="_index"
        :class="question.pQuestionId ? 'subsQuestion' : ''"
      >
        <template v-if="question.questionType == 1">
          <el-radio-group v-model="question.answerId">
            <el-radio
              :label="answer.answerId"
              v-for="(answer, index) in question.chooseContent"
              @change="dealRadioValue(answer, question, _index)"
              :key="index"
              >{{ answer.answerContent }}</el-radio
            >
          </el-radio-group>
          <div v-if="isFlag">
            <div v-for="(ite, i) in question.linkAnswerInfo" :key="i">
              <div v-if="ite.type == 2">
                <div>{{ ite.linkContent }}</div>
                <el-date-picker
                  v-model="ite.content"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
              <div v-if="ite.type == 1">
                <div>{{ ite.linkContent }}</div>
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="ite.content"
                  maxlength="20"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>
          </div>
        </template>
        <template v-if="question.questionType == 2">
          <!-- <el-radio-group v-model="question.answerId">
                  <el-radio
                    :label="answer.answerId"
                    v-for="(answer, index) in question.chooseContent"
                    @change="dealRadioValue(answer, question, _index)"
                    :key="index"
                    >{{ answer.answerContent }}</el-radio
                  >
                </el-radio-group> -->
          <el-checkbox-group v-model="question.checkBox">
            <el-checkbox
              v-for="item in question.chooseContent"
              @change="handleCheckedCitiesChange(question)"
              :label="item.answerId"
              :key="item.answerId"
              >{{ item.answerContent }}</el-checkbox
            >
          </el-checkbox-group>
          <el-input
            type="textarea"
            placeholder="请输入其他信息"
            v-model="question.textarea"
            maxlength="50"
            show-word-limit
            v-if="isShowOther"
          >
          </el-input>
        </template>
        <template v-if="question.questionType == 3">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="question.textarea"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </template>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSubmit">提交</el-button>
      <el-button @click="cancelFillQues">取消</el-button>
    </div>
  </div>
</template>

<script>
import { timeMode, Debounce } from "@/tools/utils.js";

export default {
  name: "",
  props: ["currQuestionsInfo"],
  data() {
    return {
      questionsList: [],
      isFlag: false,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      isShowOther: false,
    };
  },
  watch: {
    questionsInfo: {
      // 数据变化时执行的逻辑代码
      handler(newName, oldName) {
        console.log(newName);
        console.log(oldName);
      },
      // 开启深度监听
      deep: true,
    },
  },
  components: {},

  computed: {},

  mounted() {
    this.myDeBonce = new Debounce(500);
    console.log(this.currQuestionsInfo);
    this.getQuestionsList(this.currQuestionsInfo.id);
  },

  methods: {
    // 提交
    async onSubmit() {
      await this.myDeBonce.debounceEnd();

      console.log(this.questionsList, " this.questionsList--onSubmit-");
      // 必填校验
      for (let i = 0; i < this.questionsList.length; i++) {
        let item = this.questionsList[i];
        if (item.questionType == 1 && !item.answerId) {
          return this.$message.error("请选择" + item.questionContent);
        }
        if (item.questionType == 2 && !item.checkBox.length) {
          return this.$message.error(item.questionContent);
        }
        if (item.questionContent == "近期有无再次住院情况?") {
          for (let v = 0; v < item.chooseContent.length; v++) {
            let ite = item.chooseContent[v];
            if (
              item.questionType == 1 &&
              item.linkAnswerInfo.length &&
              ite.answerRemark == 1 &&
              ite.answerId == item.answerId
            ) {
              for (let j = 0; j < item.linkAnswerInfo.length; j++) {
                let val = item.linkAnswerInfo[j];
                if (!val.content)
                  return this.$message.error("请填写" + val.linkContent);
              }
            }
          }
        }
      }

      // 数据处理
      let arr = [];
      this.questionsList.forEach((item) => {
        console.log(item);
        if (item.questionType == 1 && !item.linkAnswerInfo.length) {
          arr.push({
            questionId: item.questionId,
            answerInfo: [
              {
                answerContent: "",
                answerId: item.answerId,
                answerPictureList: [],
                hospitalTime: "",
                inpatientDepartment: "",
                reason: "",
                specificSituation: "",
              },
            ],
          });
        }
        if (item.questionType == 1 && item.linkAnswerInfo.length) {
          let newArr = [];
          item.linkAnswerInfo.forEach((ite) => {
            newArr.push({
              answerContent: "",
              answerId: item.answerId,
              answerPictureList: [],
              hospitalTime:
                ite.type == 2
                  ? ite.content
                    ? timeMode(ite.content).datestr
                    : ""
                  : "",
              inpatientDepartment:
                ite.linkContent == "住院科室" ? ite.content : "",
              reason: ite.linkContent == "原因" ? ite.content : "",
              specificSituation: "",
            });
          });
          arr.push({
            questionId: item.questionId,
            answerInfo: newArr,
          });
        }
        if (item.questionType == 2) {
          let linkAnswerInfo = item.linkAnswerInfo;
          let list = [];
          item.checkBox.forEach((ite) => {
            linkAnswerInfo.forEach((it) => {
              list.push({
                answerContent: "",
                answerId: ite,
                answerPictureList: [],
                hospitalTime: "",
                inpatientDepartment: "",
                reason: "",
                specificSituation: it.answerId == ite ? item.textarea : "",
              });
            });
          });
          arr.push({
            questionId: item.questionId,
            answerInfo: list,
          });
        }
        if (item.questionType == 3) {
          if (item.textarea) {
            arr.push({
              questionId: item.questionId,
              answerInfo: [
                {
                  answerContent: item.textarea,
                  answerId: "",
                  answerPictureList: [],
                  hospitalTime: "",
                  inpatientDepartment: "",
                  reason: "",
                  specificSituation: "",
                },
              ],
            });
          }
        }
      });

      const params = {
        followUpId: this.currQuestionsInfo.id,
        questions: JSON.stringify(arr),
      };
      console.log("////", params, arr);
      this.$http
        .submitQuestionnaire(params)
        .then((res) => {
          console.log(res);
          if (res.result.code !== 1) {
            return this.$message.error("提交失败");
          } else {
            this.$message.success("提交成功!");
            this.$emit("cancelQuestion", false);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(`提交失败:${err.msg}`);
        });
    },
    // 取消
    cancelFillQues() {
      //   this.$router.push("taskcenter");
      this.$emit("cancelQuestion", false);
    },
    //获取问卷列表数据
    async getQuestionsList(followId) {
      const params = {
        followUpId: followId + "",
      };
      const res = await this.$http.getFollowUpQuestionsList(params);

      this.questionsList = res.result.data;
      this.questionsList.forEach((item) => {
        item.chooseContent = item.chooseContent
          ? JSON.parse(item.chooseContent)
          : [];

        item.chooseContent.sort(this.compare("answerId"));

        item.linkAnswerInfo = item.linkAnswerInfo
          ? JSON.parse(item.linkAnswerInfo)
          : [];
      });
    },
    // 排序
    compare(property) {
      return function (a, b) {
        return a[property] - b[property];
      };
    },
    //单选框选择事件
    dealRadioValue(answer, questions, index) {
      questions.requestValue = {
        questionId: answer.questionId,
        answerId: questions.userAnswerId,
      };
      let _params = {
        questionId: questions.questionId,
      };
      // if (!questions.trueAnswerId) return;
      let res;
      // let trueAnswerIdArr = [];
      // questions.trueAnswerId.forEach((item) => {
      //   trueAnswerIdArr.push(item.answerId);
      // });
      // const isGetSubsQues = trueAnswerIdArr.includes(
      //   questions.userAnswerId + ""
      // );

      // 处理子问题
      if (questions.trueAnswer) {
        let trueAnswer = JSON.parse(questions.trueAnswer);
        trueAnswer.forEach(async (item) => {
          // 获取子问题
          if (item.answerId == answer.answerId) {
            res = await this.$http.getsubsQuestions(_params);
            res.result.data.forEach((item) => {
              item.questionId = item.sQuestionId;
              item.chooseContent = item.content ? JSON.parse(item.content) : [];
              item.chooseContent.sort(this.compare("answerId"));
              item.checkBox = [];

              item.linkAnswerInfo = item.linkAnswerInfo
                ? JSON.parse(item.linkAnswerInfo)
                : [];

              var _index = this.questionsList.findIndex(
                (i) => i.questionId === item.questionId
              );
              if (_index !== -1) {
                // this.questionsList.splice(_index,1)
                return;
              } else {
                this.questionsList.splice(index + 1, 0, item);
              }
            });
          } else {
            // 处理点击父问题后获取到了子问题然后又点击其他选项清除已加载的子问题
            console.log(99999);
            this.questionsList = this.questionsList.filter((ite) => {
              return ite.pQuestionId != questions.questionId;
            });
          }
        });
      }

      // 处理选项下的子选项
      if (
        questions.linkAnswerInfo.length &&
        answer.answerId == questions.linkAnswerInfo[0].answerId
      ) {
        this.isFlag = true;
      } else {
        this.isFlag = false;
      }
    },

    handleCheckedCitiesChange(value) {
      console.log(value, "value----");
      if (value.linkAnswerInfo) {
        let linkAnswerInfo = value.linkAnswerInfo;

        if (value.checkBox.includes(linkAnswerInfo[0].answerId)) {
          this.isShowOther = true;
        } else {
          this.isShowOther = false;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.formgion {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //   .title {
  //     height: 22px;
  //     font-size: 16px;
  //     font-weight: bold;
  //     color: #101b25;
  //     display: flex;
  //     align-items: center;
  //     padding: 24px 0 16px 0;
  //     &::before {
  //       display: inline-block;
  //       content: "";
  //       width: 6px;
  //       height: 22px;
  //       background: #2c89dc;
  //       margin-right: 18px;
  //     }
  //   }
  .el-form.form-box {
    // flex: 1;
    // overflow-y: scroll;
    padding: 0 24px;
    // &::-webkit-scrollbar {
    //   width: 5px;
    //   height: 5px;
    // }
    // &::-webkit-scrollbar-track-piece {
    //   background-color: rgba(0, 0, 0, 0.2);
    //   -webkit-border-radius: 6px;
    // }
    // &::-webkit-scrollbar-thumb:vertical {
    //   height: 5px;
    //   background-color: rgba(125, 125, 125, 0.7);
    //   -webkit-border-radius: 6px;
    // }
    // &::-webkit-scrollbar-thumb:horizontal {
    //   width: 5px;
    //   background-color: rgba(125, 125, 125, 0.7);
    //   -webkit-border-radius: 6px;
    // }
  }
  .btn {
    padding: 24px;
  }
}
</style>
