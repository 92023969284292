// PHQ-9 抑郁症筛查量表
export const survey = {
  title: "",
  logoPosition: "right",
  showCompletedPage: false, //问卷完成后显示完成提示
  navigateToUrl: "", //问卷完成后跳转地址
  checkErrorsMode: "onValueChanged", //答案改变时立即校验
  widthMode: "responsive",
  width: "500px",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "panel",
          name: "emotionalInfluence",
          elements: [
            {
              type: "radiogroup",
              name: "indifference",
              title: "1、做事时提不起劲或没有兴趣",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "dispirited",
              title: "2、感到心情低落、沮丧或绝望",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "sleepBadly",
              title: "3、入睡困难、睡不安稳或睡眠过多",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "tired",
              title: "4、感觉疲倦或没有活力",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "inappetence",
              title: "5、食欲不振或吃太多",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "feelBad",
              title: "6、觉得自己很糟，或觉得自己很失败，或让自己或家人失望",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "poorConcentration",
              title:
                "7、对事物专注有困难，例如阅读报纸或看电视时不能集中注意力",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "bradypragia",
              title:
                "8、动作或说话速度缓慢到别人已经觉察?或正好相反，烦躁或坐立不安、动来动去的情况更胜于平常",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "commitSuicide",
              title: "9、有不如死掉或用某种方式伤害自己的念头",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "没有",
                },
                {
                  value: "1",
                  text: "有几天",
                },
                {
                  value: "2",
                  text: "一半以上时间",
                },
                {
                  value: "3",
                  text: "几乎天天",
                },
              ],
            },
          ],
          title: "在过去的两周里,你生活中以下症状出现的频率有多少?",
          isRequired: true,
        },
      ],
    },
  ],
};

export const onlyOneTimesData = [];

// 评分等级
// 0-4为1,5-9为2,10-14为3,15-19为4，20-27为5
// const levelResult = {
//   1: "您目前没有抑郁症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
//   2: "您可能存在轻微抑郁症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持并提供适当的治疗选择。",
//   3: "您可能存在中度抑郁症状。这种情况可能会对您的生活产生重大影响，我们建议您尽快咨询专业心理健康专家。",
//   4: "您可能存在中重度抑郁症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。我们建议您尽快咨询专业心理健康专家，并帮助您应对这些症状。",
//   5: "您可能存在重度抑郁症状。这是一个紧急情况，需要专业的治疗和支持。强烈建议您立即与心理健康专家联系，以便尽快得到帮助。",
// };

function allScore(data) {
  // 评价等级
  // 0-4为1,5-9为2,10-14为3,15-19为4，20-27为5
  // let levelResult = {
  //   1: "您目前没有抑郁症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
  //   2: "您可能存在轻微抑郁症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持并提供适当的治疗选择。",
  //   3: "您可能存在中度抑郁症状。这种情况可能会对您的生活产生重大影响，我们建议您尽快咨询专业心理健康专家。",
  //   4: "您可能存在中重度抑郁症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。我们建议您尽快咨询专业心理健康专家，并帮助您应对这些症状。",
  //   5: "您可能存在重度抑郁症状。这是一个紧急情况，需要专业的治疗和支持。强烈建议您立即与心理健康专家联系，以便尽快得到帮助。"
  // };
  let levelResult = {
    1: {
      level: 1,
      title: "心理状态良好",
      desc: "您目前没有抑郁症状",
      content:
        "您目前没有抑郁症状，这是一个积极的迹象。我们鼓励您继续关注自己的情绪和心理健康。",
    },
    2: {
      level: 2,
      title: "轻度抑郁",
      desc: "您可能存在轻度抑郁",
      content:
        "您可能存在轻微抑郁症状，这是一个需要关注的信号，但请不要过于担心。我们建议您寻求心理健康专业人士的建议和支持并提供适当的治疗选择。",
    },
    3: {
      level: 3,
      title: "中度抑郁",
      desc: "您可能存在中度抑郁",
      content:
        "您可能存在中度抑郁症状。这种情况可能会对您的生活产生重大影响，我们建议您尽快咨询专业心理健康专家。",
    },
    4: {
      level: 4,
      title: "中重度抑郁",
      desc: "您可能存在中重度抑郁",
      content:
        "您可能存在中重度抑郁症状。我明白这对您来说可能是一个巨大的挑战，但请知道您不必独自应对。我们建议您尽快咨询专业心理健康专家，并帮助您应对这些症状。",
    },
    5: {
      level: 5,
      title: "重度抑郁",
      desc: "您可能存在重度抑郁",
      content:
        "您可能存在重度抑郁症状。这是一个紧急情况，需要专业的治疗和支持。强烈建议您立即与心理健康专家联系，以便尽快得到帮助。",
    },
  };
  const PHQ = eval(Object.values(data).join("+"));
  let result = {};
  if (0 <= PHQ && PHQ <= 4) {
    result = levelResult[1];
  }
  if (5 <= PHQ && PHQ <= 9) {
    result = levelResult[2];
  }
  if (10 <= PHQ && PHQ <= 14) {
    result = levelResult[3];
  }
  if (15 <= PHQ && PHQ <= 19) {
    result = levelResult[4];
  }
  if (20 <= PHQ && PHQ <= 27) {
    result = levelResult[5];
  }
  console.log(data, PHQ, result);
  return {
    score: { allScore: PHQ },
    result: { levelResult: result },
  };
}

export const calculator = (data) => {
  console.log(data);

  let resultInfo = allScore(data);
  return {
    conclusion: resultInfo.result, //结论
    score: resultInfo.score, //分数
    answerInfo: data, //回答答案
    desc: "", //问卷特殊数据
  };
};
