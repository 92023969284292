// 匹兹堡睡眠质量指数 PSQI
export const survey = {
  title: "",
  logoPosition: "right",
  showCompletedPage: false, //问卷完成后显示完成提示
  navigateToUrl: "", //问卷完成后跳转地址
  checkErrorsMode: "onValueChanged", //答案改变时立即校验
  widthMode: "responsive",
  width: "500px",
  pages: [
    {
      name: "page1",
      title: "",
      elements: [
        {
          type: "text",
          name: "sackTime",
          title: "近1个月，晚上上床睡觉通常____点钟",
          defaultValue: "20:00",
          isRequired: true,
          inputType: "time",
        },
        {
          type: "radiogroup",
          name: "sleepDuration",
          title: "近1个月，从上床到入睡通常需要多久？",
          isRequired: true,
          choices: [
            {
              // value: `{"a":"1","b":"<=15分钟"}`,
              value: 0,
              text: "<=15分钟",
            },
            {
              value: "1",
              text: "16～30分钟",
            },
            {
              value: "2",
              text: "31～60分钟",
            },
            {
              value: "3",
              text: "≥60分钟",
            },
          ],
        },
        {
          type: "text",
          name: "wakeupTime",
          title: "近1个月，通常早上____点起床",
          defaultValue: "08:00",
          isRequired: true,
          inputType: "time",
          validators: [
            {
              type: "expression",
              text: "起床时间不能与睡觉时间相同！",
              expression: "{sackTime}<>{wakeupTime} ",
            },
          ],
        },
        {
          type: "text",
          name: "sleepLongth",
          title: "近1个月，每夜通常实际睡眠____小时？",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "请输入正整数",
              regex: "^[+]{0,1}(\\d+)$",
            },
          ],
          inputType: "number",
          min: 1,
          max: 24,
          step: 1,
        },
        {
          type: "panel",
          name: "factorsAffectingSleep",
          elements: [
            {
              type: "radiogroup",
              name: "difficultyFallingAsleep",
              title: "a.入睡困难（30分钟内不能入睡）",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "earlyAwakening",
              title: "b.夜间易醒或早醒",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "nightToilet",
              title: "c.夜间去厕所",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "disturbanceInRespiration",
              title: "d.呼吸不畅",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "cough",
              title: "e.咳嗽或鼾声高",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "feelCold",
              title: "f.感觉冷",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "sensibleHeat",
              title: "g.感觉热",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "horribleDream",
              title: "h.做恶梦",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "pain",
              title: "i.疼痛不适",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "otherEffects",
              title: "j.其它影响睡眠的事情",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "0",
                  text: "无",
                },
                {
                  value: "1",
                  text: "<1次/周",
                },
                {
                  value: "2",
                  text: "1-2次/周",
                },
                {
                  value: "3",
                  text: "≥3次/周",
                },
              ],
            },
          ],
          title: "请选择近1个月，因下列情况影响您睡眠的答案：",
          isRequired: true,
          showNumber: true,
        },
        {
          type: "radiogroup",
          name: "sleepQuality",
          title: "近1个月，总的来说，您认为自己的睡眠质量",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "很好",
            },
            {
              value: "1",
              text: "较好",
            },
            {
              value: "2",
              text: "较差",
            },
            {
              value: "3",
              text: "很差",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "drugHypnosis",
          title: "近1个月，您用药物催眠的情况",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "无",
            },
            {
              value: "1",
              text: "<1次/周",
            },
            {
              value: "2",
              text: "1-2次/周",
            },
            {
              value: "3",
              text: "≥3次/周",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "drowsiness",
          title: "近1个月，您常感到困倦吗",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "无",
            },
            {
              value: "1",
              text: "<1次/周",
            },
            {
              value: "2",
              text: "1-2次/周",
            },
            {
              value: "3",
              text: "≥3次/周",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "energy",
          title: "近1个月，您做事情的精力不足吗",
          isRequired: true,
          choices: [
            {
              value: "0",
              text: "没有",
            },
            {
              value: "1",
              text: "偶尔有",
            },
            {
              value: "2",
              text: "有时有",
            },
            {
              value: "3",
              text: "经常有",
            },
          ],
        },
      ],
      triggers: [
        {
          type: "runexpression",
          setToName: "energy",
          runExpression: "{sleepDuration}+{difficultyFallingAsleep}",
        },
      ],
      calculatedValues: [
        {
          name: "var1",
          expression: "{sleepDuration}+{difficultyFallingAsleep}",
        },
      ],
    },
  ],
  showThemeTab: true,
};

export const onlyOneTimesData = [];

// A睡眠质量计分
function sleepQualityScore(sleepQuality) {
  return Number(sleepQuality);
}

//  B入睡时间计分，sleepDuration+difficultyFallingAsleep累加：0计0分，1-2计1分，3-4计2分，5-6计3分
function sleepDurationScore(sleepDuration, difficultyFallingAsleep) {
  let socreArr = [0, 1, 1, 2, 2, 3, 3];
  return socreArr[Number(sleepDuration) + Number(difficultyFallingAsleep)];
}

// C睡眠时间计分：＜5计3分，[5-6)计2分，[6-7]计1分， >7计0分，
function sleepLongthScore(sleepLongth) {
  let numScore = 0;
  if (sleepLongth < 5) {
    numScore = 3;
  }
  if (5 <= sleepLongth && sleepLongth < 6) {
    numScore = 2;
  }
  if (6 <= sleepLongth && sleepLongth <= 7) {
    numScore = 1;
  }
  if (sleepLongth > 7) {
    numScore = 0;
  }
  return numScore;
}

// D睡眠效率计分:
/**
 * 1.床上时间 = 问题 3(起床时间)- 问题1(上床时间)
 * 2.睡眠效率 = 问题4(睡眠时间)/ 床上时间 X 100%
 * 3.成分D计分为，睡眠效率>85%计 0分，75~84%计 1 分，65~74%计 2 分，<65%计 3 分。
 */
function sleepEfficiencyScore(wakeupTime, sackTime, sleepLongth) {
  let wakeupTimeNum =
    Number(wakeupTime.split(":")[0]) +
    Number((Number(wakeupTime.split(":")[1]) / 60).toFixed(1));
  let sackTimeNum =
    Number(sackTime.split(":")[0]) +
    Number((Number(sackTime.split(":")[1]) / 60).toFixed(1));
  console.log(sackTimeNum, wakeupTimeNum);
  let timeInBed = null;
  if (sackTimeNum > wakeupTimeNum) {
    timeInBed = 24 - (sackTimeNum - wakeupTimeNum);
  }
  if (sackTimeNum < wakeupTimeNum) {
    timeInBed = Math.abs(sackTimeNum - wakeupTimeNum);
  }
  console.log(timeInBed);
  let sleepEfficiency = ((sleepLongth / timeInBed) * 100).toFixed(1);
  let numScore = 0;
  if (sleepEfficiency < 65) {
    numScore = 3;
  }
  if (65 <= sleepEfficiency && sleepEfficiency < 75) {
    numScore = 2;
  }
  if (75 <= sleepEfficiency && sleepEfficiency <= 85) {
    numScore = 1;
  }
  if (85 < sleepEfficiency) {
    numScore = 0;
  }
  console.log(sleepEfficiency);
  return numScore;
}

// E睡眠障碍计分
// 5b至5j累加，0计0 分，1~9 计1分，10~18 计2 分，19~27 计3 分
function sleepDisordersScore(data) {
  let numScore = 0;
  let sleepDisorders =
    Number(data.earlyAwakening) +
    Number(data.nightToilet) +
    Number(data.disturbanceInRespiration) +
    Number(data.cough) +
    Number(data.feelCold) +
    Number(data.sensibleHeat) +
    Number(data.horribleDream) +
    Number(data.pain) +
    Number(data.otherEffects);
  console.log(sleepDisorders);
  if (sleepDisorders == 0) {
    numScore = 0;
  }
  if (1 <= sleepDisorders && sleepDisorders <= 9) {
    numScore = 1;
  }
  if (10 <= sleepDisorders && sleepDisorders <= 18) {
    numScore = 2;
  }
  if (19 <= sleepDisorders && sleepDisorders <= 27) {
    numScore = 3;
  }
  return numScore;
}

// F催眠药物计分
function drugHypnosisScore(drugHypnosis) {
  return Number(drugHypnosis);
}
//  G 日间功能障碍计分，drowsiness+energy累加：0计0分，1-2计1分，3-4计2分，5-6计3分
function drowsinessScore(drowsiness, energy) {
  let socreArr = [0, 1, 1, 2, 2, 3, 3];
  return socreArr[Number(drowsiness) + Number(energy)];
}

// PSQI 总分=成分 A+成分 B+成分 C+成分 D+成分 E+成分 F+成分G

function allScore(data) {
  // 评价等级
  // let levelResult = {
  //   1: "您的睡眠质量很好，请继续保持良好的生活习惯。良好的睡眠可以增强注意力和专注力，提高情绪稳定性，并有助于更好地处理日常的压力和挑战。",
  //   2: "您的睡眠质量较好，良好的睡眠对冠心病患者来说十分重要，它有助于增强注意力和专注力，提高情绪稳定性，维持免疫系统的正常功能，促进身体的康复和修复，以及增进的幸福感",
  //   3: "您的睡眠状态较差，这可能对您的冠心病状况产生不利影响。冠心病患者通常需要更多的关注和管理睡眠，因为良好的睡眠对心脏健康至关重要。不良的睡眠习惯可能导致血压升高、心率不稳定以及更多的冠心病症状。",
  //   4: "您的睡眠状态很差，这对您的冠心病病情可能有着严重的不利影响。长时间的睡眠不足或质量很差可能导致血压异常、心律不齐、心脏过度负担等问题，进一步加剧冠心病症状。",
  // };
  let levelResult = {
    1: {
      level: 1,
      title: "睡眠质量很好",
      desc: "您的睡眠质量很好",
      content:
        "您的睡眠质量很好，请继续保持良好的生活习惯。良好的睡眠可以增强注意力和专注力，提高情绪稳定性，并有助于更好地处理日常的压力和挑战。",
    },
    2: {
      level: 2,
      title: "睡眠质量较好",
      desc: "您的睡眠质量较好",
      content:
        "您的睡眠质量较好，良好的睡眠对冠心病患者来说十分重要，它有助于增强注意力和专注力，提高情绪稳定性，维持免疫系统的正常功能，促进身体的康复和修复，以及增进的幸福感",
    },
    3: {
      level: 3,
      title: "睡眠状态较差",
      desc: "您的睡眠状态较差",
      content:
        "您的睡眠状态较差，这可能对您的冠心病状况产生不利影响。冠心病患者通常需要更多的关注和管理睡眠，因为良好的睡眠对心脏健康至关重要。不良的睡眠习惯可能导致血压升高、心率不稳定以及更多的冠心病症状。",
    },
    4: {
      level: 4,
      title: "睡眠状态很差",
      desc: "您的睡眠状态很差",
      content:
        "您的睡眠状态很差，这对您的冠心病病情可能有着严重的不利影响。长时间的睡眠不足或质量很差可能导致血压异常、心律不齐、心脏过度负担等问题，进一步加剧冠心病症状。",
    },
  };
  let obj = {
    sleepQualityScore: sleepQualityScore(data.sleepQuality),
    sleepDurationScore: sleepDurationScore(
      data.sleepDuration,
      data.difficultyFallingAsleep
    ),
    sleepLongthScore: sleepLongthScore(data.sleepLongth),

    sleepEfficiencyScore: sleepEfficiencyScore(
      data.wakeupTime,
      data.sackTime,
      data.sleepLongth
    ),
    sleepDisordersScore: sleepDisordersScore(data),
    drugHypnosisScore: drugHypnosisScore(data.drugHypnosis),
    drowsinessScore: drowsinessScore(data.drowsiness, data.energy),
  };
  const PSQI = eval(Object.values(obj).join("+"));
  let result = {};
  if (0 <= PSQI && PSQI <= 5) {
    result = levelResult[1];
  }
  if (6 <= PSQI && PSQI <= 10) {
    result = levelResult[2];
  }
  if (11 <= PSQI && PSQI <= 15) {
    result = levelResult[3];
  }
  if (16 <= PSQI && PSQI <= 21) {
    result = levelResult[4];
  }
  console.log(obj, PSQI, result);
  return {
    score: { otherScore: obj, allScore: PSQI },
    result: { levelResult: result },
  };
}

export const calculator = (data) => {
  console.log(data);
  console.log(allScore(data));

  let resultInfo = allScore(data);
  return {
    conclusion: resultInfo.result, //结论
    score: resultInfo.score, //分数
    answerInfo: data, //回答答案
    desc: "", //问卷特殊数据
  };
};
