<template>
  <div class="main page-container">
    <Breadcrumb></Breadcrumb>
    <div class="topMsg">
      <div class="leftMsg">
        <div class="baseMsgBox">
          <div class="first">
            <div class="patient-name">{{ baseMsg.userName || "--" }}</div>
            <el-tooltip
              placement="bottom"
              :disabled="diagnosisList.length == 0"
            >
              <div slot="content">{{ diagnosisList.join("；") }}</div>
              <div class="diagnosis">
                <div
                  class="diagnosis-item"
                  v-for="(item, i) in diagnosisList"
                  :key="i"
                >
                  {{ item }}
                </div>
              </div>
            </el-tooltip>
          </div>
          <div class="second">
            <div class="title">基本信息：</div>
            <div class="content">
              <span>{{ baseMsg.gender == 1 ? "男" : "女" }}</span>
              <el-divider direction="vertical" />
              <span>{{ baseMsg.age || "--" }}岁</span>
              <el-divider direction="vertical" />
              <div class="maturityTime">
                服务到期时间：<span class="time">{{
                  baseMsg.expirationDate
                    ? timeMode(baseMsg.expirationDate).datestr
                    : "--"
                }}</span>
              </div>
            </div>
          </div>
          <div class="third">
            <div class="title align">备 注：</div>
            <span class="patientRemark" :title="baseMsg.remarks">{{
              baseMsg.remarks || "--"
            }}</span>
          </div>
        </div>
        <div class="four">
          <div class="title align">手 术：</div>
          <el-tooltip
            placement="bottom"
            :disabled="operationMsgList.length == 0"
          >
            <div slot="content">
              {{ operationMsgList.join("；") }}
            </div>
            <div class="operationBox">
              {{ operationMsgList.join("；") || "--" }}
              <!-- <div
              class="itemOperation"
              v-for="(item, i) in operationMsgList"
              :key="item.id"
            >
              {{ item.conclusion }}
              <span class="operationTime"
                >（{{ timeMode(item.surgeryTime).datestr }}）</span
              >
              <span v-if="i < operationMsgList.length - 1">;</span>
            </div> -->
            </div>
          </el-tooltip>
        </div>
        <div class="five">
          <div class="title">当前用药：</div>
          <el-tooltip
            placement="bottom"
            :disabled="currentMedicationList.length == 0"
          >
            <div slot="content">
              {{ currentMedicationList.join("；") }}
            </div>
            <div class="operationBox">
              <!-- <div
                class="itemOperation"
                v-for="(item, i) in currentMedicationList"
                :key="i"
              >
                {{ item.drugName }}
                <span v-if="i < currentMedicationList.length - 1">，</span>
              </div> -->
              {{ currentMedicationList.join("；") || "--" }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="rightMsg">
        <div class="item">
          <div class="itemTitle">风险等级</div>
          <div class="valueBox">
            <div
              class="value"
              v-for="(item, i) in riskGrade"
              :key="i"
              :title="item.name + '：' + item.values"
            >
              <span>{{ item.name }}：</span>
              <span>{{ item.values }}</span>
              <el-divider
                direction="vertical"
                v-if="i < riskGrade.length - 1"
              />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">危险因素</div>
          <div class="valueBox">
            <div class="value" :title="highRiskFactor.highRisk">
              {{ highRiskFactor.highRisk || "--" }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">危险因素</div>
          <div class="valueBox">
            <div class="value">
              随访完成度{{
                highRiskFactor.compliance
                  ? highRiskFactor.compliance.followUp
                  : 0
              }}%， 复查完成度{{
                highRiskFactor.compliance
                  ? highRiskFactor.compliance.review
                  : 0
              }}%， 血压心率检测率{{
                highRiskFactor.compliance ? highRiskFactor.compliance.blood : 0
              }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="left-list">
        <ul v-show="!showQuestion">
          <div v-if="questionnaireList.length == 0">暂无数据</div>
          <li class="list-item" v-for="(item, i) in questionnaireList" :key="i">
            <div class="info">
              <p class="info-name" v-if="item.questionnaireType == 3">
                第{{ item.times }}次随访
              </p>
              <p class="info-name" v-else>{{ item.questionnaireName }}</p>
              <div
                class="info-status"
                v-show="item.status === 0 || item.status == 4"
              >
                待完成
              </div>
              <div
                class="info-status unstyle"
                v-show="item.status === 1 || item.status > 4"
              >
                已完成
              </div>
            </div>
            <el-button
              type="primary"
              :disabled="item.status === 1 || item.status > 4"
              @click="lookQuestionnaireDetail(item)"
              >查看详情</el-button
            >
          </li>
        </ul>
        <div class="questionnaire-box" v-if="showQuestion">
          <div class="title">{{ currQuestionsInfo.name }}</div>
          <div class="questionnaire-content">
            <QuestionDetail
              :currQuestionsInfo="currQuestionsInfo"
              :id="1"
              v-if="currQuestionsInfo.type == 3"
              @cancelQuestion="showQuestion = false"
            />
            <SurveyBuilder
              ref="surveyElement"
              :data="surveyJson"
              :calculator="surveyCalculator"
              :onlyOneTimesData="onlyOneTimesData"
              v-if="currQuestionsInfo.type == 1"
            />
          </div>
          <div class="btn" v-if="currQuestionsInfo.type == 1">
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="showQuestion = false">取消</el-button>
          </div>
        </div>
      </div>
      <div class="right-tool" v-if="type == 1">
        <el-tabs v-model="activeName">
          <el-tab-pane label="呼叫" name="first">
            <div class="relations-phone-list">
              <div
                class="title"
                v-for="(item, index) in relationsList"
                :key="index"
              >
                <div>{{ item.name }} :{{ item.phone }}</div>
                <span @click="telNum(item.phone)" style="cursor: pointer"
                  >呼叫</span
                >
              </div>
            </div>
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item
                label="是否应答"
                prop="isAnswer"
                label-width="100px"
              >
                <div class="radio-box">
                  <el-radio v-model="teachFrom.isAnswer" :label="1"
                    >是</el-radio
                  >
                  <el-radio v-model="teachFrom.isAnswer" :label="0"
                    >否</el-radio
                  >
                </div>
                <div class="radio-box" v-show="teachFrom.isAnswer == 0">
                  <el-radio v-model="noAnswerType" :label="1"
                    >下次跟进</el-radio
                  >
                  <el-radio v-model="noAnswerType" :label="0"
                    >无法联系</el-radio
                  >
                </div>
              </el-form-item>
              <el-form-item
                label="下次提醒时间"
                prop="nextTime"
                label-width="100px"
                v-show="teachFrom.isAnswer === 0 && noAnswerType == 1"
              >
                <div class="teachTime-box">
                  <el-date-picker
                    style="margin-bottom: 12px"
                    v-model="teachFrom.nextTime"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                  <el-time-picker
                    v-model="teachFrom.handM"
                    value-format="HH:mm:ss"
                    placeholder="选择任意时间点"
                  >
                  </el-time-picker>
                </div>
              </el-form-item>
            </el-form>
            <div class="btngroups" v-if="teachFrom.isAnswer === 0">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent(0)"
                >确 定</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import patientinfocard from "@/views/taskcenter/components/patientinfocard";
// import { Debounce } from "../../tools/utils";
// import { timeMode } from "@/tools/utils.js";
import SurveyBuilder from "@/components/Survey/SurveyBuilder";
// import { survey, calculator, onlyOneTimesData } from "./survey.config";
// import { sleepSurvey, calculator2 } from "./survey2.config";
// import { survey3, calculator3 } from "./survey3.config";
// import { survey4, calculator4 } from "./survey4.config";
// import { survey5, calculator5 } from "./survey5.config";
import QuestionDetail from "./components/questionDetail.vue";

import { Debounce, timeMode, compare } from "../../tools/utils";
import { mapState } from "vuex";

export default {
  name: "FollowUpDetail",
  data() {
    return {
      timeMode,
      type: 1, //1待跟进，2已完成
      surveyJson: null,
      onlyOneTimesData: null,
      calculator: null,
      surveyData: {},
      baseMsg: {},
      diagnosisList: [],
      operationMsgList: [],
      currentMedicationList: [],
      riskGrade: [
        {
          name: "预后风险",
          values: "",
        },
        {
          name: "ASCVD风险",
          values: "",
        },
        {
          name: "出血风险",
          values: "",
        },
        {
          name: "猝死风险",
          values: "",
        },
      ],
      highRiskFactor: {},
      activeName: "first",
      relationsList: [],
      teachFrom: {
        teachId: "",
        userId: "",
        isAnswer: 1,
        des: "",
        teachTime: "",
        name: "",
        office: "",
        nextTime: "",
        handM: "",
      },
      noAnswerType: 1,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        // shortcuts: [
        //   {
        //     text: "今天",
        //     onClick(picker) {
        //       picker.$emit("pick", new Date());
        //     },
        //   },
        //   {
        //     text: "昨天",
        //     onClick(picker) {
        //       const date = new Date();
        //       date.setTime(date.getTime() - 3600 * 1000 * 24);
        //       picker.$emit("pick", date);
        //     },
        //   },
        //   {
        //     text: "一周前",
        //     onClick(picker) {
        //       const date = new Date();
        //       date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        //       picker.$emit("pick", date);
        //     },
        //   },
        // ],
      },

      questionnaireList: [],
      currQuestionsInfo: null,
      showQuestion: false,
    };
  },

  components: {
    QuestionDetail,
    SurveyBuilder,
  },

  computed: {
    ...mapState({
      isLogin: (state) => state.calling.isLogin,
    }),
  },

  mounted() {
    this.myDeBonce = new Debounce(500);
    console.log(this.$route.query);
    this.type = sessionStorage.getItem("actionType");
    console.log(this.type);
    this.getQuestionnaireList();
    this.getImageInfo(this.$route.query.userId);
    this.getQuRuleInfo(this.$route.query.userId);
    this.getRelationsList(this.$route.query.userId);
    // this.surveyData["defaultSurvey"] = require("./survey2.config");
    // this.surveyJson = this.surveyData["defaultSurvey"].survey;
    // this.onlyOneTimesData = this.surveyData["defaultSurvey"].onlyOneTimesData;
    // this.calculator = this.surveyData["defaultSurvey"].onlyOneTimesData;
  },

  methods: {
    async onSubmit() {
      await this.myDeBonce.debounceEnd();
      //触发问卷提交方法
      this.$refs.surveyElement.saveSurveyResult();
    },
    surveyCalculator(data) {
      console.log(data);
      let result = this.calculator(data);
      console.log(result);
      let params = {
        userQuestionnaireId: this.currQuestionsInfo.id,
        conclusion: JSON.stringify(result.conclusion),
        score: result.score ? JSON.stringify(result.score) : null,
        answerInfo: JSON.stringify(result.answerInfo),
        desc: result.desc ? JSON.stringify(result.desc) : null,
      };
      this.$http
        .saveWriteUserQuestionnaire(params)
        .then((res) => {
          console.log(res);
          if (res.result.msg === "success") {
            this.$message.success("提交成功!");
            this.getQuestionnaireList();

            this.showQuestion = false;
          } else {
            this.$message.error("提交失败!");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(`提交失败:${err.msg}`);
        });
    },
    // 获取用户画像信息
    getImageInfo(userId) {
      this.$http.getImageInfo({ userId }).then((res) => {
        console.log(res);
        this.baseMsg = res.result.data.userInfo;
        this.baseMsg.age = this.baseMsg.birth
          ? this.getAge(this.baseMsg.birth)
          : "";
        this.baseMsg.expirationDate = timeMode(
          this.baseMsg.expirationDate
        ).datestr;

        this.diagnosisList = res.result.data.clinicalDiagnosis;
        this.operationMsgList =
          res.result.data.operation && res.result.data.operation.survey
            ? JSON.parse(res.result.data.operation.survey).map(
                (re) =>
                  re.conclusion +
                  "(" +
                  this.timeMode(re.surgeryTime).datestr +
                  ")"
              )
            : [];
        this.currentMedicationList = res.result.data.drugInfo
          ? JSON.parse(res.result.data.drugInfo).map((re) => re.drugName)
          : [];

        for (const key in res.result.data.riskGrade) {
          let levelInfo = res.result.data.riskGrade[key];
          levelInfo =
            levelInfo && levelInfo.length > 0
              ? levelInfo.sort(compare("score", 1))[0].riskLevel
              : "--";
          console.log(key);
          if (key == "prognosticRisk") {
            this.riskGrade[0].values = levelInfo || "--";
          }
          if (key == "ascvdRisk") {
            this.riskGrade[1].values = levelInfo || "--";
          }
          if (key == "bleedingRisk") {
            this.riskGrade[2].values = levelInfo || "--";
          }
          if (key == "deathRisk") {
            this.riskGrade[3].values = levelInfo || "--";
          }
        }
        console.log(this.riskGrade);
      });
      this.$http.getHighRiskFactor({ userId }).then((res) => {
        console.log(res);
        this.highRiskFactor = res.result.data;
      });
    },
    //计算年龄
    getAge(val) {
      let currentYear = new Date().getFullYear(); //当前的年份
      let calculationYear = new Date(val).getFullYear(); //计算的年份
      const wholeTime = currentYear + val.substring(4); //周岁时间
      const calculationAge = currentYear - calculationYear; //按照年份计算的年龄
      //判断是否过了生日
      if (new Date().getTime() > new Date(wholeTime).getTime()) {
        return calculationAge;
      } else {
        return calculationAge - 1;
      }
    },
    // 查询患者只填写一次的相关问卷问题数据
    getQuRuleInfo(userId) {
      this.$http.getQuRuleInfo({ userId }).then((res) => {
        console.log(res);
        this.onlyOneTimesData =
          res.result.data && res.result.data.desc
            ? eval(Object.keys(JSON.parse(res.result.data.desc)))
            : [];
        console.log(this.onlyOneTimesData);
      });
    },

    cancelbtn() {
      this.teachFrom.handM = "";
      this.teachFrom.nextTime = "";
      this.teachFrom.des = "";
    },
    confirmEvent() {
      if (this.noAnswerType == 1) {
        // 需要下次跟进
        this.updateFollowUpReq();
      } else {
        // 无法联系，设置隐藏问卷列表数据
        this.updataHiddenFollowUp();
      }
    },
    //下次提醒
    async updateFollowUpReq() {
      await this.myDeBonce.debounceEnd();

      if (this.teachFrom.nextTime === "" || this.teachFrom.handM === "")
        return this.$message.error("请先填写下次提醒时间");
      let arr = this.questionnaireList.map((re) => {
        return {
          id: re.id,
          questionnaireType: re.questionnaireType,
          answerStatus: 0,
          nextTime: this.teachFrom.nextTime + " " + this.teachFrom.handM,
        };
      });
      let params = {
        userQuestionnaireList: arr,
      };
      const res = await this.$http.updateRemindFollowUp(params);
      if (res.result.msg === "success") {
        this.$message.success("处理成功!");
        // 更新问卷列表数据
        this.getQuestionnaireList();
      } else {
        this.$message.error("处理失败!");
      }
    },
    // 无法联系
    updataHiddenFollowUp() {
      let params = {
        questionnaireList: this.questionnaireList.map((re) => {
          return {
            id: re.id,
            questionnaireType: re.questionnaireType,
          };
        }),
      };
      this.$http
        .updataHiddenFollowUp(params)
        .then((res) => {
          console.log(res);
          if (res.result.msg === "success") {
            this.$message.success("处理成功!");
            this.getQuestionnaireList();
            this.showQuestion = false;
            this.$router.go(-1);
          } else {
            this.$message.error("处理失败!");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(`处理失败:${err.msg}`);
        });
    },
    //获取当前患者通讯录
    getRelationsList(val) {
      let params = {
        userId: val,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        this.relationsList = res.result.data;
      });
    },
    // 获取问卷列表
    getQuestionnaireList() {
      // 1待跟进，2已完成
      if (this.type == 1) {
        this.getUserQuestionnaireList(this.$route.query.userId);
      } else {
        this.getPendingDetails(
          this.$route.query.userId,
          this.$route.query.date
        );
      }
    },
    // 获取待跟进患者问卷列表
    getUserQuestionnaireList(userId) {
      this.$http.getUserQuestionnaireList({ userId }).then((res) => {
        console.log(res);
        this.questionnaireList = res.result.data.filter(
          (re) => re.isHidden == 0
        );
        console.log(this.questionnaireList);
      });
    },
    // 获取已完成患者问卷列表
    getPendingDetails(userId, date) {
      let params = {
        userId,
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
      };
      this.$http.getPendingDetails(params).then((res) => {
        console.log(res);
        this.questionnaireList = res.result.data.filter(
          (re) => re.isHidden == 0
        );
        console.log(this.questionnaireList);
      });
    },
    // 查看问卷详情
    lookQuestionnaireDetail(info) {
      // type:问卷类型   1生活方式问卷  3随访问卷
      this.currQuestionsInfo = {
        type: info.questionnaireType,
        name:
          info.questionnaireType == 3
            ? `第${info.times}次随访`
            : info.questionnaireName,
        id: info.id,
      };
      let currSurvey = "";
      if (info.questionnaireName == "一般情况调查问卷") {
        this.surveyData["defaultSurvey"] = this.surveyData["defaultSurvey"]
          ? this.surveyData["defaultSurvey"]
          : require("./survey.config");
        currSurvey = "defaultSurvey";
      }
      if (info.questionnaireName == "睡眠质量调查问卷") {
        this.surveyData["sleepSurvey"] = this.surveyData["sleepSurvey"]
          ? this.surveyData["sleepSurvey"]
          : require("./survey2.config");
        currSurvey = "sleepSurvey";
      }
      if (info.questionnaireName == "生活质量调查问卷") {
        this.surveyData["liveSurvey"] = this.surveyData["liveSurvey"]
          ? this.surveyData["liveSurvey"]
          : require("./survey3.config");
        currSurvey = "liveSurvey";
      }
      if (info.questionnaireName == "焦虑评估调查问卷") {
        this.surveyData["anxietySurvey"] = this.surveyData["anxietySurvey"]
          ? this.surveyData["anxietySurvey"]
          : require("./survey4.config");
        currSurvey = "anxietySurvey";
      }
      if (info.questionnaireName == "抑郁评估调查问卷") {
        this.surveyData["depressionSurvey"] = this.surveyData[
          "depressionSurvey"
        ]
          ? this.surveyData["depressionSurvey"]
          : require("./survey5.config");
        currSurvey = "depressionSurvey";
      }
      if (info.questionnaireType == 1) {
        this.surveyJson = this.surveyData[currSurvey].survey;
        // this.onlyOneTimesData = this.surveyData[currSurvey].onlyOneTimesData;
        this.calculator = this.surveyData[currSurvey].calculator;
      } else {
        currSurvey = "";
      }
      this.showQuestion = true;
      console.log(this.currQuestionsInfo, this.surveyData, this.surveyJson);
    },
    telNum(phone) {
      if (this.isLogin === 1) {
        ClinkAgent.previewOutcall({ tel: phone });
      } else {
        this.$message.warning("请先登录呼叫中心!");
      }
    },
  },
};
</script>
<style lang="less" scoped>
ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.main {
  position: relative;
  min-width: 1200px;
  .topMsg {
    display: flex;
    justify-content: space-between;
    .el-divider--vertical {
      margin: 0 16px;
    }
    .leftMsg {
      width: 54%;
      height: 264px;
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      box-sizing: border-box;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      line-height: 20px;
      .baseMsgBox {
        min-height: 120px;
        max-height: 150px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #708293;
        white-space: nowrap;
        margin-right: 8px;
      }
      .align {
        text-align: justify;
        word-spacing: 18px;
        letter-spacing: 2px;
        hyphens: auto;
      }
      .first {
        width: 100%;
        height: 28px;
        display: flex;
        align-items: center;
        // overflow: hidden;
        .patient-name {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #111111;
          margin-right: 16px;
        }
        .diagnosis {
          flex: 1;
          height: 28px;
          overflow: hidden;
          padding: 4px 0;
          box-sizing: border-box;
          .diagnosis-item {
            display: inline-block;
            min-width: 28px;
            height: 20px;
            line-height: 20px;
            background: #ebf6ff;
            border-radius: 2px;
            font-size: 12px;
            color: #1779e5;
            padding: 0 6px;
            margin-right: 8px;
            margin-bottom: 4px;
            box-sizing: border-box;
          }
        }

        .conversation {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #0a73e4;
          cursor: pointer;
          .conversation {
            width: 16px;
            margin-right: 3px;
          }
        }
      }
      .second {
        margin: 12px 0;
        display: flex;
        align-items: center;
        .content {
          display: flex;
          align-items: center;
          .phone {
            display: flex;
            align-items: center;
          }
          .TelephoneAnswering {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            cursor: pointer;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111;
          }
          .keyPerson {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111;
            display: flex;
            align-items: center;
            .person {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0a73e4;
              display: flex;
              align-items: center;
            }
          }
          .maturityTime {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111;
            .time {
              color: #0a73e4;
            }
          }
        }
      }
      .third {
        min-height: 30px;
        max-height: 56px;
        display: flex;
        .patientRemark {
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #203549;
          max-height: 100%;
          overflow-y: hidden;
        }
      }
      .four {
        max-height: 45px;
        display: flex;
        margin: 0 0 24px 16px;
        .operationBox {
          display: flex;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          max-height: 100%;
          color: #203549;
          flex-wrap: wrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .operationTime {
            color: #708293;
          }
          .itemOperation {
            // margin-bottom: 4px;
          }
        }
      }
      .five {
        max-height: 45px;
        display: flex;
        margin-left: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .operationBox {
          display: flex;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #203549;
          flex-wrap: wrap;
          .itemOperation {
            // margin-bottom: 4px;
          }
        }
      }
    }
    .rightMsg {
      width: 45%;
      height: 264px;
      background: #ffffff;
      border-radius: 4px;
      padding: 16px;
      box-sizing: border-box;
      .item {
        padding: 12px 16px;
        box-sizing: border-box;
        border-radius: 4px;
        height: 72px;
        margin-bottom: 8px;
        .itemTitle {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #101b25;
        }
        .valueBox {
          display: flex;
          margin-top: 16px;
          color: #101b25;
          font-size: 14px;
          .value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .item:first-child {
        background: url("../../assets/images/risk-level.png") no-repeat;
        background-size: 100% 100%;
      }
      .item:nth-child(2) {
        background: url("../../assets/images/risk-factor.png") no-repeat;
        background-size: 100% 100%;
      }
      .item:nth-child(3) {
        background: url("../../assets/images/compliance.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .main-box {
    flex: 1;
    display: flex;
    padding-top: 16px;
    box-sizing: border-box;
    overflow-y: hidden;
    .left-list {
      flex: 1;
      background: #ffffff;
      border-radius: 4px;

      ul {
        height: 100%;
        overflow-y: scroll;
        padding: 24px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track-piece {
          background-color: rgba(0, 0, 0, 0.2);
          -webkit-border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb:vertical {
          height: 5px;
          background-color: rgba(125, 125, 125, 0.7);
          -webkit-border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb:horizontal {
          width: 5px;
          background-color: rgba(125, 125, 125, 0.7);
          -webkit-border-radius: 6px;
        }
        > li.list-item {
          width: 100%;
          height: 70px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dedede;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          padding: 0 16px 0 0;
          box-sizing: border-box;
          position: relative;
          &::before {
            display: inline-block;
            content: "";
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 4px;
            height: 100%;
            background: #1779e5;
            // background: #dedede;
            border-radius: 100px 0px 0px 100px;
          }
          .info {
            flex: 1;
            padding-left: 16px;
            .info-name {
              margin: 0;
              font-size: 14px;
              font-weight: bold;
              color: #101b25;
              line-height: 32px;
            }
            .info-status {
              font-size: 12px;
              color: #1779e5;
              // line-height: 20px;
            }
            .info-status.unstyle {
              color: #4d4d4d;
            }
          }
          .el-button {
            width: 104px;
            height: 32px;
            background: #2c89dc;
            border-radius: 2px;
          }
        }
      }
      .questionnaire-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        .title {
          height: 22px;
          font-size: 16px;
          font-weight: bold;
          color: #101b25;
          display: flex;
          align-items: center;
          padding: 24px 0 16px 0;
          &::before {
            display: inline-block;
            content: "";
            width: 6px;
            height: 22px;
            background: #2c89dc;
            margin-right: 18px;
          }
        }
        .questionnaire-content {
          flex: 1;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }
          &::-webkit-scrollbar-track-piece {
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb:vertical {
            height: 5px;
            background-color: rgba(125, 125, 125, 0.7);
            -webkit-border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb:horizontal {
            width: 5px;
            background-color: rgba(125, 125, 125, 0.7);
            -webkit-border-radius: 6px;
          }
        }
        .btn {
          padding: 24px;
        }
      }
    }
    .right-tool {
      width: 556px;
      background: #ffffff;
      border-radius: 4px;
      margin-left: 16px;
      padding: 0 24px;
      box-sizing: border-box;
      .relations-phone-list {
        margin-bottom: 12px;
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #606266;
          text-align: right;
          display: flex;
          justify-content: space-between;
          padding: 8px;
          span {
            color: #409eff;
          }
          div:first-child {
            padding-left: 46px;
          }
        }
      }
      .teachTime-box {
        display: flex;
        flex-direction: column;
      }
      .btngroups {
        text-align: center;
      }
    }
  }
}
</style>
