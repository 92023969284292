// 生活质量调查表
export const survey = {
  title: "",
  logoPosition: "right",
  showCompletedPage: false, //问卷完成后显示完成提示
  navigateToUrl: "", //问卷完成后跳转地址
  checkErrorsMode: "onValueChanged", //答案改变时立即校验
  widthMode: "responsive",
  width: "500px",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "radiogroup",
          name: "healthCondition",
          title: "总体来讲，您的健康状况是:",
          isRequired: true,
          choices: [
            {
              value: "5",
              text: "非常好",
            },
            {
              value: "4.4",
              text: "很好",
            },
            {
              value: "3.4",
              text: "好",
            },
            {
              value: "2",
              text: "一般",
            },
            {
              value: "1",
              text: "差",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "healthConditionBefore",
          title: "跟1年以前比您觉得自己的健康状况是",
          isRequired: true,
          choices: [
            {
              value: "5",
              text: "比1年前好多了",
            },
            {
              value: "4",
              text: "比1年前好一些",
            },
            {
              value: "3",
              text: "跟1年前差不多",
            },
            {
              value: "2",
              text: "比1年前差一些",
            },
            {
              value: "1",
              text: "比1年前差多了",
            },
          ],
        },
        {
          type: "panel",
          name: "factorsAffectingSleep",
          elements: [
            {
              type: "radiogroup",
              name: "heavyPhysicalActivity",
              title: "(1) 重体力活动。如跑步举重、参加剧烈运动等:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "moderateActivity",
              title:
                "(2)适度的活动。如移动一张桌子、扫地、打太极、做简单体操等:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "dailyActivities",
              title: "(3) 手提日用品。如买菜、购物等",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "aFewFloor",
              title: "(4) 上几层楼梯：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "noeFloor",
              title: "(5) 上一层楼梯：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "bendDown",
              title: "(6) 弯腰、屈膝、下蹲：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "longDistanceWalk",
              title: "(7) 步行1500米以上的路程：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "middleDistanceWalk",
              title: "(8) 步行1000米以上的路程：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "shortWalk",
              title: "(9) 步行100米以上的路程：",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "dressOneself",
              title: "(10)自己洗澡、穿衣",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "限制很大",
                },
                {
                  value: "2",
                  text: "有些限制",
                },
                {
                  value: "3",
                  text: "毫无限制",
                },
              ],
            },
          ],
          title:
            "以下这些问题都和日常活动有关。请您想一想，您的健康状况是否限制了这些活动? 如果有限制，程度如何?",
          isRequired: true,
          showNumber: true,
        },
        {
          type: "panel",
          name: "dailyImpact",
          elements: [
            {
              type: "radiogroup",
              name: "reduceTime",
              title: "(1) 减少了工作或其他活动时间:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "unfinishedBusiness",
              title: "(2)本来想要做的事情只能完成一部分:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "workLimit",
              title: "(3)想要干的工作或活动种类受到限制:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "increaseOfDifficulties",
              title: "(4) 完成工作或其他活动困难增多(比如需要额外的努力):",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
          ],
          title:
            "在过去 1 个月里，您的工作和日常活动有无因为身体健康的原因而出现以下这些问题?",
          isRequired: true,
          showNumber: true,
        },
        {
          type: "panel",
          name: "emotionalInfluence",
          elements: [
            {
              type: "radiogroup",
              name: "reduceTimeEmo",
              title: "(1) 减少了工作或其他活动时间:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "unfinishedBusinessEmo",
              title: "(2)本来想要做的事情只能完成一部分:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "carelessnessEmo",
              title: "(3)干事情不如平时仔细",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "是",
                },
                {
                  value: "2",
                  text: "不是",
                },
              ],
            },
          ],
          title:
            "在过去 1个月里，您的工作和日常活动有无因为情绪的原因(如压抑或忧虑) 而出现以下这些问题?",
          isRequired: true,
          showNumber: true,
        },
        {
          type: "radiogroup",
          name: "emotionsAffectSocialInteraction",
          title:
            "在过去 1 个月里，您的健康或情绪不好在多大程度上影响了您与家人、朋友、邻居或集体的正常社会交往?",
          isRequired: true,
          choices: [
            {
              value: "5",
              text: "完全没有影响",
            },
            {
              value: "4",
              text: "有一点影响",
            },
            {
              value: "3",
              text: "中等影响",
            },
            {
              value: "2",
              text: "影响很大",
            },
            {
              value: "1",
              text: "影响非常大",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "bodyAches",
          title: "在过去 1 个月里，您有身体疼痛吗?",
          isRequired: true,
          choices: [
            {
              value: "1",
              text: "完全没有疼痛",
            },
            {
              value: "2",
              text: "有很轻微疼痛",
            },
            {
              value: "3",
              text: "有轻微疼痛",
            },
            {
              value: "4",
              text: "中等疼痛",
            },
            {
              value: "5",
              text: "严重疼痛",
            },
            {
              value: "6",
              text: "很严重疼痛",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "bodyAchesEffect",
          title: "在过去 1 个月里，您的身体疼痛影响了您的工作和家务吗?",
          isRequired: true,
          choices: [
            {
              value: "1",
              text: "完全没有影响",
            },
            {
              value: "2",
              text: "有一点影响",
            },
            {
              value: "3",
              text: "中等影响",
            },
            {
              value: "4",
              text: "影响很大",
            },
            {
              value: "5",
              text: "影响非常大",
            },
          ],
        },
        {
          type: "panel",
          name: "panel3",
          elements: [
            {
              type: "radiogroup",
              name: "lifeRich",
              title: "(1) 您觉得生活充实:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "6",
                  text: "所有的时间",
                },
                {
                  value: "5",
                  text: "大部分时间",
                },
                {
                  value: "4",
                  text: "比较多时间",
                },
                {
                  value: "3",
                  text: "一部分时间",
                },
                {
                  value: "2",
                  text: "小部分时间",
                },
                {
                  value: "1",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "sensitivity",
              title: "(2) 您是一个敏感的人:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "4",
                  text: "一部分时间",
                },
                {
                  value: "5",
                  text: "小部分时间",
                },
                {
                  value: "6",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "insensitivityToPleasure",
              title: "(3) 您的情绪非常不好，什么事都不能使您高兴起来:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "4",
                  text: "一部分时间",
                },
                {
                  value: "5",
                  text: "小部分时间",
                },
                {
                  value: "6",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "calmness",
              title: "(4) 您的心理很平静:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "6",
                  text: "所有的时间",
                },
                {
                  value: "5",
                  text: "大部分时间",
                },
                {
                  value: "4",
                  text: "比较多时间",
                },
                {
                  value: "3",
                  text: "一部分时间",
                },
                {
                  value: "2",
                  text: "小部分时间",
                },
                {
                  value: "1",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "fullOfEnergy",
              title: "(5) 您做事精力充沛:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "6",
                  text: "所有的时间",
                },
                {
                  value: "5",
                  text: "大部分时间",
                },
                {
                  value: "4",
                  text: "比较多时间",
                },
                {
                  value: "3",
                  text: "一部分时间",
                },
                {
                  value: "2",
                  text: "小部分时间",
                },
                {
                  value: "1",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "downcast",
              title: "(6)您的情绪低落",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "4",
                  text: "一部分时间",
                },
                {
                  value: "5",
                  text: "小部分时间",
                },
                {
                  value: "6",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "beCompletelyExhausted",
              title: "(7)您觉得筋疲力尽:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "4",
                  text: "一部分时间",
                },
                {
                  value: "5",
                  text: "小部分时间",
                },
                {
                  value: "6",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "sunbeam",
              title: "(8) 您是个快乐的人:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "6",
                  text: "所有的时间",
                },
                {
                  value: "5",
                  text: "大部分时间",
                },
                {
                  value: "4",
                  text: "比较多时间",
                },
                {
                  value: "3",
                  text: "一部分时间",
                },
                {
                  value: "2",
                  text: "小部分时间",
                },
                {
                  value: "1",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "boredom",
              title: "(9) 您感觉厌烦:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "4",
                  text: "一部分时间",
                },
                {
                  value: "5",
                  text: "小部分时间",
                },
                {
                  value: "6",
                  text: "没有这种感觉",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "influenceActivity",
              title: "(10) 不健康影响了您的社会活动(如走亲访友):",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "所有的时间",
                },
                {
                  value: "2",
                  text: "大部分时间",
                },
                {
                  value: "3",
                  text: "比较多时间",
                },
                {
                  value: "3",
                  text: "一部分时间",
                },
                {
                  value: "4",
                  text: "小部分时间",
                },
                {
                  value: "5",
                  text: "没有这种感觉",
                },
              ],
            },
          ],
          title:
            "以下这些问题是关于过去 1 个月里您自己的感觉，对每一条问题所说的事情，您的情况是什么样的?",
          isRequired: true,
          showNumber: true,
        },
        {
          type: "panel",
          name: "panel4",
          elements: [
            {
              type: "radiogroup",
              name: "easySick",
              title: "(1)我好象比别人容易生病:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "绝对正确",
                },
                {
                  value: "2",
                  text: "大部分正确",
                },
                {
                  value: "3",
                  text: "不能肯定",
                },
                {
                  value: "4",
                  text: "大部分错误",
                },
                {
                  value: "5",
                  text: "绝对错误",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "health",
              title: "(2)我跟周围人一样健康:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "5",
                  text: "绝对正确",
                },
                {
                  value: "4",
                  text: "大部分正确",
                },
                {
                  value: "3",
                  text: "不能肯定",
                },
                {
                  value: "2",
                  text: "大部分错误",
                },
                {
                  value: "1",
                  text: "绝对错误",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "goBad",
              title: "(3)我认为我的健康状况在变坏:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "1",
                  text: "绝对正确",
                },
                {
                  value: "2",
                  text: "大部分正确",
                },
                {
                  value: "3",
                  text: "不能肯定",
                },
                {
                  value: "4",
                  text: "大部分错误",
                },
                {
                  value: "5",
                  text: "绝对错误",
                },
              ],
            },
            {
              type: "radiogroup",
              name: "inExcellentCondition",
              title: "(4)我的健康状况非常好:",
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: "5",
                  text: "绝对正确",
                },
                {
                  value: "4",
                  text: "大部分正确",
                },
                {
                  value: "3",
                  text: "不能肯定",
                },
                {
                  value: "2",
                  text: "大部分错误",
                },
                {
                  value: "1",
                  text: "绝对错误",
                },
              ],
            },
          ],
          title:
            "以下这些问题是关于过去 1 个月里您自己的感觉，对每一条问题所说的事情，您的情况是什么样的?",
          isRequired: true,
          showNumber: true,
        },
      ],
    },
  ],
};

export const onlyOneTimesData = [];

// 1 生理机能计分：题3分数累计后，通过公式计算：PF=(score-10)/20*100
function physicalFunctioning(data) {
  // let {HeavyPhysicalActivity,moderateActivity,dailyActivities} = data
  let obj = {
    heavyPhysicalActivity: data.heavyPhysicalActivity,
    moderateActivity: data.moderateActivity,
    dailyActivities: data.dailyActivities,
    aFewFloor: data.aFewFloor,
    noeFloor: data.noeFloor,
    bendDown: data.bendDown,
    longDistanceWalk: data.longDistanceWalk,
    middleDistanceWalk: data.middleDistanceWalk,
    shortWalk: data.shortWalk,
    dressOneself: data.dressOneself,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const PF = (((score - 10) / 20) * 100).toFixed(1);
  console.log(score, PF);
  return PF;
}

// 2 生理只能计分：题4分数累计后，通过公式计算：RP=(score-4)/4*100
function rolePhysical(data) {
  // let {HeavyPhysicalActivity,moderateActivity,dailyActivities} = data
  let obj = {
    reduceTime: data.reduceTime,
    unfinishedBusiness: data.unfinishedBusiness,
    workLimit: data.workLimit,
    increaseOfDifficulties: data.increaseOfDifficulties,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const RP = (((score - 4) / 4) * 100).toFixed(1);
  console.log(score, RP);
  return RP;
}

// 3 躯体疼痛计分：题7、题8分数累计后，通过公式计算：BP=(score-2)/10*100
/**
 * 题7计分，选项1-6分别对应分数：[6,5.4,4.2,3.1,2.2,1]
 * 题8计分，2种情况：
 * 情况1：若题7选项为2-6，则题8选项1-5分别对应分数：[5,4,3,2,1]
 * 情况2：若题7选项为1，则题8选项1-5分别对应分数：[6,4.75,3.5,2.25,1]
 */
function bodilyPain(data) {
  // 7:bodyAches,8:bodyAchesEffect
  let bodyAchesScoreScore, bodyAchesEffectScore;
  bodyAchesScoreScore = [6, 5.4, 4.2, 3.1, 2.2, 1][data.bodyAches - 1];
  if (data.bodyAches == 1) {
    bodyAchesEffectScore = [6, 4.75, 3.5, 2.25, 1][data.bodyAchesEffect - 1];
  } else {
    bodyAchesEffectScore = [5, 4, 3, 2, 1][data.bodyAchesEffect - 1];
  }
  console.log(bodyAchesScoreScore, bodyAchesEffectScore);
  let score = bodyAchesScoreScore + bodyAchesEffectScore;
  const BP = (((score - 2) / 10) * 100).toFixed(1);
  console.log(score, BP);
  return BP;
}

// 4 一般健康状况计分：题1、题10分数累计后，通过公式计算：GH=(score-5)/20*100
function generalHealth(data) {
  let obj = {
    healthCondition: data.healthCondition,
    easySick: data.easySick,
    health: data.health,
    goBad: data.goBad,
    inExcellentCondition: data.inExcellentCondition,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const GH = (((score - 5) / 20) * 100).toFixed(1);
  console.log(score, GH);
  return GH;
}
// 5 精力计分：题9.1、9.5、9.7、9.9分数累计后，通过公式计算：VT=(score-4)/20*100
function vitality(data) {
  let obj = {
    lifeRich: data.lifeRich,
    fullOfEnergy: data.fullOfEnergy,
    beCompletelyExhausted: data.beCompletelyExhausted,
    boredom: data.boredom,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const VT = (((score - 4) / 20) * 100).toFixed(1);
  console.log(score, VT);
  return VT;
}

// 6 社会功能计分：题6、9.10分数累计后，通过公式计算：SF=(score-2)/8*100
function socialFunctioning(data) {
  let obj = {
    emotionsAffectSocialInteraction: data.emotionsAffectSocialInteraction,
    influenceActivity: data.influenceActivity,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const SF = (((score - 2) / 8) * 100).toFixed(1);
  console.log(score, SF);
  return SF;
}

// 7 情感职能计分：题5分数累计后，通过公式计算：RE=(score-3)/3*100
function roleEmotional(data) {
  let obj = {
    reduceTimeEmo: data.reduceTimeEmo,
    unfinishedBusinessEmo: data.unfinishedBusinessEmo,
    carelessnessEmo: data.carelessnessEmo,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const RE = (((score - 3) / 3) * 100).toFixed(1);
  console.log(score, RE);
  return RE;
}

// 8 精神健康计分：题9.2、9.3、9.4、9.6、9.8分数累计后，通过公式计算：MH=(score-5)/25*100
function mentalHealth(data) {
  let obj = {
    sensitivity: data.sensitivity,
    insensitivityToPleasure: data.insensitivityToPleasure,
    calmness: data.calmness,
    downcast: data.downcast,
    sunbeam: data.sunbeam,
  };
  console.log(obj);
  let score = eval(Object.values(obj).join("+"));
  const MH = (((score - 5) / 25) * 100).toFixed(1);
  console.log(score, MH);
  return MH;
}

// 9 健康变化计分：题2分数累计后，通过公式计算：HT=(score-1)/4*100
function reportedHealthTransition(data) {
  let score = Number(data.healthConditionBefore);
  const HT = (((score - 1) / 4) * 100).toFixed(1);
  console.log(score, HT);
  return HT;
}

//最终结果数据
function allScore(data) {
  const resultMsg =
    "健康状况调查问卷(SF-36)是一个简短的调查表，旨在多维度评估您的健康和功能状况，每项满分 100 分，分值越高表示您的健康功能状态越好。";
  let obj = {
    physicalFunctioning: physicalFunctioning(data),
    rolePhysical: rolePhysical(data),
    bodilyPain: bodilyPain(data),
    generalHealth: generalHealth(data),
    vitality: vitality(data),
    socialFunctioning: socialFunctioning(data),
    roleEmotional: roleEmotional(data),
    mentalHealth: mentalHealth(data),
    reportedHealthTransition: reportedHealthTransition(data),
  };

  console.log(obj, resultMsg);
  return {
    score: { otherScore: obj },
    result: { levelResult: resultMsg },
  };
}

export const calculator = (data) => {
  console.log(data);
  let resultInfo = allScore(data);
  return {
    conclusion: resultInfo.result, //结论
    score: resultInfo.score, //分数
    answerInfo: data, //回答答案
    desc: "", //问卷特殊数据
  };
};
